import {FC, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import qs from 'qs';
//redux
import {useDispatch, useSelector} from 'react-redux';
import {getOrgVideos} from '../../../redux/organization-service/actions';
import {selectorGetOrgInfo, selectorGetOrgVideos} from '../../../redux/organization-service/selector';
import {selectorGetUserRolesByOrg, selectorIsUserMarketer} from '../../../redux/user-service/selector';
//api
import {API} from '../../../core/API';
import {SearchStatusesRequest, VOLUNTEER} from '@joc/api-gateway';
//translation
import {useTranslation} from 'react-i18next';
//components
import MarketingTabs from './MarketingTabs';
import Media from './Media';
import Snackbar from '../OrganizationSettings/Snackbar';
import Statuses from './Statuses';
//helpers
import {MarketingContextProvider} from './context';
import {MARKETING_TABS} from './enums';
import {getInitialTab} from './helpers';
import {ORG_SETTINGS_TABS_TITLES} from './constants';
import {queryDataConfig} from '../Opportunities/data';
import {IMarketingTabsConfig} from './interfaces';
import {INITIAL_PAGINATION} from '../../../core/constants';
//styles
import styles from './Marketing.module.scss';

const MarketingPage: FC = () => {
  const {t} = useTranslation(['marketing', 'popup', 'settings', 'messages']);
  const dispatch = useDispatch();
  const orgVideos = useSelector(selectorGetOrgVideos);
  const history = useHistory();
  const {search} = useLocation();
  const [activeTab, setActiveTab] = useState<MARKETING_TABS>(getInitialTab(search));
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const organizationInfo = useSelector(selectorGetOrgInfo);
  const [statusTotal, setStatusTotal] = useState(0);
  const isMarketer = useSelector(selectorIsUserMarketer);
  const userRolesByOrg = useSelector(selectorGetUserRolesByOrg);

  useEffect(() => {
    dispatch(getOrgVideos());
    if (organizationInfo) {
      API.searchStatusesByAdmin(
        undefined,
        organizationInfo?.id,
        SearchStatusesRequest.fromJS({pagination: INITIAL_PAGINATION})
      ).then(({total}) => setStatusTotal(total || 0));
    }
  }, [organizationInfo]);

  const setTabUrl = (tab: MARKETING_TABS) => {
    const queryData = qs.parse(search, queryDataConfig);
    const newQueryString = qs.stringify({...((queryData && queryData) || {}), tab});
    history.replace({search: newQueryString});
  };

  const handleActiveTab = (value: MARKETING_TABS) => {
    setActiveTab(value);
    setTabUrl(value);
  };

  const ActiveTabComponent = useMemo(() => {
    switch (activeTab) {
      case MARKETING_TABS.Media:
        return <Media />;
      case MARKETING_TABS.Status:
        return <Statuses />;
      default:
        return <Media />;
    }
  }, [activeTab]);

  const tabsConfig: Array<IMarketingTabsConfig> = useMemo(
    () => [
      {
        title: ORG_SETTINGS_TABS_TITLES.media,
        counter: orgVideos?.length || 0,
        currentTab: MARKETING_TABS.Media,
      },
      ...(userRolesByOrg?.filter((i) => i.roleName === VOLUNTEER.ADMIN).length || isMarketer
        ? [
            {
              title: ORG_SETTINGS_TABS_TITLES?.status,
              counter: statusTotal || 0,
              currentTab: MARKETING_TABS.Status,
            },
          ]
        : []),
    ],
    [orgVideos, statusTotal, isMarketer, userRolesByOrg]
  );

  return (
    <div className={styles.marketing}>
      <h2 className={styles.marketing__title}>{t('marketing:title')}</h2>
      <MarketingContextProvider value={{activeTab, isUpdateSuccess, setIsUpdateSuccess, setStatusTotal}}>
        <>
          <MarketingTabs
            tabsConfig={tabsConfig}
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
            parentClassName={styles.marketing__tabs}
            parentTabItemClassName={styles.marketing__tabs_item}
          />
        </>
        <div className={styles.marketing__layout}>{ActiveTabComponent}</div>
        {(isUpdateSuccess && (
          <Snackbar
            isOpenSnackbar={isUpdateSuccess}
            setIsOpenSnackbar={setIsUpdateSuccess}
            hideDuration={3000}
            title={t('messages:success')}
            text={t('settings:successMessage')}
          />
        )) || <></>}
      </MarketingContextProvider>
    </div>
  );
};

export default MarketingPage;
